.project-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 24px;
    border: 1px solid #f3f4f6;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .project-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }
  
  .project-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
  }
  
  .project-icon {
    width: 20px;
    height: 20px;
    color: #6b7280;
  }
  
  .project-title {
    font-size: 1.125rem; /* Equivalent to text-lg */
    font-weight: 500;
    color: #111827;
  }
  
  .project-description {
    color: #4b5563;
    font-size: 0.875rem; /* Equivalent to text-sm */
    margin-bottom: 16px;
  }
  
  .project-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .project-forms-count,
  .project-date {
    font-size: 0.875rem;
    color: #6b7280;
  }
  .project-icon {
    width: 30px;
    height: 30px;
    color: #6b7280; /* Gray color */
  }
  
  