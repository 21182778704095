@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.submit-response-container{
    display: flex;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    align-items: center;
    width: 100%;
    margin-top: 100px;
   /* height: 100vh; */
}

.submit-response-inner-container{
    /* width: 50%; */
    /* margin-bottom: 30px; */
    display:flex;
    flex-direction: column;
    align-items: center;
    
}

.img-style{
    width: 80px;
    margin: 0;
}

.title-text{
    font-family: "Libre Baskerville", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 55px;
    margin: 8px;
    white-space: nowrap;
}

.subtitle-text{
    font-family: "Libre Baskerville", serif;
    font-weight: 300;
    font-size: 18px;
}

@media only screen and (max-width:500px){
    .title-text{
        font-size: 35px;
    }

    .img-style{
        width: 40px;
    }
    .subtitle-text{
        font-size: 12px;
    }
}