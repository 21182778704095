
.update-drag-drop-main-container{
    display: flex;
    flex-direction: row;

}

.update-drag-container{
    padding: 20px 20px;
    display: flex;
    justify-content: flex-start;
    /* padding-top: 10px; */
    height: 100vh;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    border-right: #d5d4d4 1px solid;
    overflow-y: scroll;
}

.update-title-uploadimage{
    margin-top: 10px;
    display: grid;
     grid-template-columns: 1fr 1fr;
     width: 80%;
   
}
.update-button-wrap-container{
    width: 100%;
  display: flex;
  flex-direction: row;

}
.update-upload-title-image-button{
    border-left-color: #FCFCFD;
 border-right-color: #f0f0f0;
 border-bottom-color:  rgb(223, 214, 214);
 border-top-color: #FCFCFD;
 height:54px ;
 border-style: solid;
 margin-left: -5px;
 width:95%;
 background-color:#FCFCFD;
    /* flex: 1; */
   }
   .update-info-text{
       position: relative;
       left:0;
       font-size: 11px;
       color: rgb(245, 42, 42);
   }
   .update-upload-title-image-button:active{
       border-color: rgb(245, 104, 104);
       border-left-color: #e7e4e4;
   
   }

   .update-input-box-title{
   
    height: 50px;
    border-top:0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 30px;
    font-weight: 300;
    color: #494646;
    /* border: 1px solid #ccc; */
    border-bottom: 1;
     border-bottom-color: rgb(223, 214, 214); 
    background-color:#FCFCFD;
  }
 
.update-img-icon{
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.update-drag-item-container{
    min-width: 200px;
    padding: 1rem ;
    background-color: white;
    border: #eeeeee 1px solid;
    align-items: center;
    display: flex;
    box-shadow: 2px 2px 2px rgb(0,0,0,0.2);
    /* padding-left: 20px; */
    border-radius: 10px;
    gap: 1rem;
    cursor: pointer;
    /* flex-direction: column; */
    /* padding-top: 5px; */
}
.update-drag-item-container :hover , .update-drag-item-container:active{
    transform: scale(1.1);
}
.update-drop-main-container-outer{
    flex: 1;
    display: flex;
    justify-content: center;
    min-height: 100vh;

}


.update-drop-container-style{
    background-color:#FCFCFD;
    width:90%;
    height: 95vh;
    margin: 10px 0px;
    border: #bebebe 2px  dashed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    
}

.update-drop-widget-style{
    width:500px;
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    /* height: 60px; */
    /* padding: 20px; */
    padding-left:40px;
    padding-bottom: 10px;
    padding-top: 10px;

    justify-content: center;
}

.update-drop-box-container{
    margin-top: 10px;
    background-color:rgb(253, 253, 253);
    width: 50vh;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    border: #ecebeb 1px solid;
    box-shadow: 2px 2px 2px rgba(162, 158, 158, 0.2);
    

}
.update-text-custom-style{
    /* fontSize:18,fontWeight:'500',paddingRight:'10px' */
    font-size: 18;
    font-weight: 500;
    padding-right: 10px;
}
.update-size-width-style{
    width: 100%;
}

.update-list-Title{
    /* display: 'flex', position: 'relative', left: 0, paddingBottom: '10px' */
    display: flex;
    position: relative;
    left:0;
    padding-bottom: 10px;
}
.update-ul-list-style-container{
    /* style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }} */
    display: flex;
    flex-direction: column;
    align-items:flex-start ;
}

.update-button-view-container{
    margin-top: 30px;
    margin-bottom: 30px;

}
.update-widget-drop-map-container{
    /* style={{display:'flex',flexDirection:'row',width:'100%',marginTop:'10px'}} */
    display:flex;
    flex-direction: row;
    width: 100%;
    margin-top:10px ;
    padding-right: 0.4rem;
    padding-top: 0.3rem;
}

.update-text-design{
   
    font-weight: bold !important;
    
}
.update-form-icon{
    width: 20px;
    height: 20px;
}
.update-widget-data-accordian-view{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.update-widget-style {
    
    width: 400px;
    /* height:70px ; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 60px;
    
}


  /* title   */

   .update-title{
    /* position: relative; */
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    /* font-size:30px; */
    text-align:center; 
    line-height:1.5em; 
    padding-bottom:45px;
    font-family:"Playfair Display", serif; 
    text-transform:uppercase;
    
  }
  .updatetext {
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides overflow text */
    text-overflow: ellipsis; /* Displays ellipsis (...) when text overflows */
    max-width: 100px; /* Adjust the max-width as needed */
  }
.update-form-widget{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
  /* ------------meadia query-------------- */
  @media only screen and (max-width:1200px){
    .update-drop-container-style{
        width:92%;
     }
     .update-drag-container{
        width:100px;
    }
    .update-button-wrap-container{
        flex-direction: column;
    }
  }
  @media only screen and (max-width:800px){
    .update-drop-container-style{
        width:90%;
     }
     .update-drag-container{
        width:120px;
    }
    .update-button-wrap-container{
        flex-direction: column;
    }
    .update-title-uploadimage{
        margin-right: 10px;
        width:90%
    }
    /* .update-img-icon{
        width: 60px;
        height: 60px;
    }
    .update-drag-item-container{
        width: 65px;
        height: 65px;
    } */
  }
  @media only screen and (max-width:500px){
    .update-drop-container-style{
       width: 100%;
    }
    .update-drop-main-container-outer{
        background-color:#f0f0f0 ;
        width:250px;
        height: auto;
    }
    .update-drag-container{
        width: 90px;
    }
    .update-img-icon{
        width: 60px;
        height: 60px;
    }
    .update-drag-item-container{
        width: 65px;
        height: 65px;
    }
    .update-title{
        margin-top: 5px;
        font-size: 18px;
    }
    .update-input-box-title{
        width: 90%;
    }
    .update-drop-box-container{
        
        width:80%;
    }
    .update-drag-drop-main-container{
        display: flex;
    }
    .update-title-uploadimage{
        grid-template-columns: 1fr;
        
    }
    .update-upload-title-image-button{
        margin-top: 10px;
    }
    .update-input-box-title{
        font-size: 20px;
    }
    .update-text-design{
        font-size: 15px !important;
    }
}