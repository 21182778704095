@import url('https://fonts.googleapis.com/css2?family=Junge&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.drag-drop-main-container{
    display: flex;
    flex-direction: row;

}

.drag-container{
   padding: 20px 30px;
    display: flex;
    justify-content: flex-start;
    /* padding-top: 10px; */
    height: 100vh;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    border-right: #d5d4d4 1px solid;
    overflow-y: scroll;
}


.title-uploadimage{
    margin-top: 10px;
   display: grid;
    grid-template-columns: 1fr 200px;
    width: 80%;
    background-color: green;
}

.button-wrapcontainer{
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  background-color: bisque;
    
}
.upload-title-image-button{
 border-left-color: #FCFCFD;
 border-right-color: #f0f0f0;
 border-bottom-color:  rgb(223, 214, 214);
 border-top-color: #FCFCFD;
 height:54px ;
 border-style: solid;
 margin-left: -5px;
 background-color:#FCFCFD;
 /* margin-right: 10px; */
 /* flex: 1; */
}
.info-text{
    position: relative;
    left:0;
    font-size: 11px;
    margin: 0;
    color: rgb(245, 42, 42);
}
.upload-title-image-button:active{
    border-color: rgb(245, 104, 104);
    border-left-color: #e7e4e4;
}

.input-box-title{
    /* width: 60%; */
    height: 50px;
    border-top:0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 30px;
    font-weight: 300;
    color: #494646;
    /* border: 1px solid #ccc; */
    border-bottom: 1;
     border-bottom-color: rgb(223, 214, 214); 
    /* background-color:#FCFCFD; */
    background-color:#60609f;
  }

.drag-item-container{
   width: 100%;
    padding: 1rem ;
    background-color: white;
    border: #eeeeee 1px solid;
    align-items: center;
    display: flex;
    box-shadow: 2px 2px 2px rgb(0,0,0,0.2);
    /* padding-left: 20px; */
    border-radius: 10px;
    gap: 1rem;
    /* flex-direction: column; */
    /* padding-top: 5px; */
}
.drag-item-container:hover , .drag-item-container:active{
    transform: scale(1.1);
}
.drag-item-container p{
    margin: 0px;
}
.form-icon{
    width: 20px;
    height: 20px;
}
.img-icon{
    width: 70px;
    height: 70px;
    cursor: pointer;
}
.drop-main-container-outer{
    flex: 1;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}


.drop-container-style{
    background-color:#FCFCFD;
    width:90%;
    height: 95vh;
    margin: 10px 0px;
    border: #bebebe 2px  dashed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    
}

.drop-widget-style{
    width:300px;
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    /* height: 100px; */
    /* padding: 20px; */
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
   
}

.drop-box-container{
    margin-top: 10px;
    background-color:rgb(255, 255, 255);
    width: 350px;
    display: flex;
    border: #eeeeee 1px solid;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}
.text-custom-style{
    margin: 0;
    font-size: 18;
    font-weight: 700;
    padding-right: 10px;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-variation-settings:
    "wdth" 100;
    
}
.size-width-style{
    width: 100%;
}

.list-Title{
    font-size: smaller;
    margin: 4px 2px;
}
.ul-list-style-container{
 margin: 0px;
}
.ul-list-li{
    margin: 0px;
}

.button-view-container{
    margin-top: 30px;
    margin-bottom: 30px;

}
.widget-drop-map-container{
    
    display:flex;
    flex-direction: row;
    width: 100%;
    margin-top:10px ;
    align-items: flex-start;
    justify-content: center;
}

.text-design{
    font-size: 1rem;
    margin: 0px;
}

.widget-data-accordian-view{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.widget-data-accordian-view p{
    margin: 0px;
    font-size: smaller;
}

.form-title{
    /* position: relative; */
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    /* font-size:30px; */
    text-align:center; 
    line-height:1.5em; 
    padding-bottom:45px;
    font-family:"Playfair Display", serif; 
    text-transform:uppercase;
  }

  /* button style */
  .button-style{
    background-color: #413f3f;
    font-weight: 600 !important;
  }

  .text {
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides overflow text */
    text-overflow: ellipsis; /* Displays ellipsis (...) when text overflows */
    max-width: 100px; /* Adjust the max-width as needed */
  }

  /* backbutton css */
  .form-back-button{
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 3px 14px;
    border-radius: 0.4rem;
    display: flex;
    background-color: #fdfdfd;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: #828282 1px solid;
    align-self: self-start;
  }
  .form-back-button:hover{
   opacity: 0.9;
  }


  /* ---------formtitle---- */
  .form2-title-uploadimage {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 0.5rem;
  }
  
  .form2-input-box-title {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form2-button-wrapcontainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .form2-upload-title-image-button {
    background-color: #131517;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: x-small;
    text-align: center;
  }
  
  .form2-upload-title-image-button:hover {
    background-color: #0a0b0b;
  }
  
  .form2-info-text {
    font-size: 12px;
    color: #777;
  }
  
  /* -------end------ */
  @media only screen and (max-width:1000px){
    .drop-container-style{
        width: 95%;
    }
    .drop-main-container-outer{
    
       
    }
    .button-wrapcontainer{
        flex-direction: column;
        /* justify-content: center;
        align-items: center; */
      }
      .drag-item-container{
        width: 100%;
      }
      .title-uploadimage{
        grid-template-columns: 1fr 100px;
        width: 95%;
    }
    .upload-title-image-button{
        width: 100%;
    }
  }

  @media only screen and (max-width:800px){
    .drag-container{
        
    }
    .drop-container-style{
        width: 97%;
    }
.title-uploadimage{
   width: 90%;
}
/* .form2-button-wrapcontainer{
    flex-direction: column;

} */

}

  @media only screen and (max-width:500px){
    .drop-container-style{
        width: 100%;
        height: 100%;
    }
    .img-icon{
        width: 60px;
        height: 60px;
    }
    .drag-item-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.2rem;
    }
    .drag-item-container p{
        font-size: xx-small;
    }
    
    .title-uploadimage{
        display: grid;
        grid-template-columns: 1fr;
        width: 95%;
    }
    .button-wrapcontainer{
       margin-top: 10px;
          
      }

      .input-box-title{
        font-size: 30px;
      }
      .upload-title-image-button:active{
        border-left-color:  rgb(245, 104, 104);
      }
  }