@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.admin-home-container{
    background-color: #E6F2FD;
    /* display: flex; */
    padding: 2px;
    min-height: 110vh;
}

.admin-card-box{
    margin-top: 50px;
    /* background-color: rgb(224, 198, 150); */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.upper-view-box{
    width: 70%;
    /* background-color: rgb(138, 187, 171); */
    display: flex;
    justify-content: flex-end;
    margin-right: 70px;
    align-items: center;
    margin-bottom: 5px;

}

.count-box{
    background-color: white;
    width: 80px;
    height: 50px;
    border-radius: 5px;
    margin-right:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
}
.text-heading-small{
    margin: 0;
    font-size: 15px;
    font-weight: 300;
}
.count-text{
    margin: 0;
    font-size: 23px;
    font-weight: 600;
}

.status-button{
    width: 70px;
    height: 40px;
    /* background-color:#2E6C29 ; */
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-color: #2E6C29;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.linear-card{
    width: 80%;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 200px;
    flex-direction: row;
    gap: 3px;
    box-shadow: 10px 0px 10px rgb(0,0,0,0.2);
    
}
.name-block{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
   
}

.labelText{
    position: relative;
    top: 5px;
    margin: 0;
    color: #5A5555;
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;

}
.nameShadeblock{
    background-color: #E6F2FD;
    width: 70%;
    height: 50px;
    border-radius: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.email-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}
.title-box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    height: 50px;

}
.below-text{
    font-size: 17px;
    font-weight: 500;
    color: #5A5555;
}

.log-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.button-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.view-button{
    background-color:#7236A0;
    height:46px ;
    width:125px;
    color: white;
    font-size: 18px;
    border-radius: 20px;
    cursor: pointer;
    transition: opacity 0.15s;
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
}

@media only screen and (max-width:1000px){
    .linear-card{
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr 1fr 180px;
        height: 80px;
    }
    .nameShadeblock{
        background-color: #E6F2FD;
        width: 80%;
        height: 30px;
    }
    .title-box{
        height: 30px;
    }
    .below-text{
        font-size: 15px;
        font-weight: 500;
    }
}

@media only screen and (max-width:800px){
    .linear-card{
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr 1fr 150px;
    }
    .upper-view-box{
        width: 80%;
    }
    .view-button{
        background-color:#7236A0;
        height:40px ;
        width:105px;
        font-size: 15px;
    }
    .nameShadeblock{
        background-color: #E6F2FD;
        width: 90%;
        height: 30px;
    }
    .title-box{
        height: 30px;
    }
    .below-text{
        font-size: 14px;
        font-weight: 500;
    }
    
    .labelText{
        font-size: 13px;
    }

    /* upper count and status button */
    .count-box{
        background-color: white;
        width: 77px;
        height: 48px;
        border-radius: 4px;
       
    }
 
    
}

@media only screen and (max-width:650px){
    .linear-card{
        width: 93%;
        grid-template-columns: 1fr 1fr 1fr 1fr 80px;
    }
    .upper-view-box{
        width: 85%;
    }
    .view-button{
        background-color:#7236A0;
        height:35px ;
        width:70px;
        font-size: 13px;
    }
    .nameShadeblock{
        background-color: #E6F2FD;
        width: 90%;
        height: 40px;
    }
    .below-text{
        font-size: 12px;
        /* font-weight: 500; */
    }
    
    .labelText{
        font-size: 12px;
    }

 
}

@media only screen and (max-width:500px){
    .linear-card{
        width: 93%;
        grid-template-columns: 1fr 1fr 1fr 1fr 80px;
        height: 70px;
    }
    .upper-view-box{
        width: 85%;
    }
    .view-button{
        background-color:#7236A0;
        height:30px ;
        width:65px;
        font-size: 12px;
    }
    .nameShadeblock{
        background-color: #E6F2FD;
        width: 90%;
        height: 30px;
    }
    .title-box{
        width: 90%;
        height: 30px;
    
    }
    .below-text{
        font-size: 10px;
        /* font-weight: 500; */
    }
    
    .labelText{
        font-size: 8px;
    }

    /*  */

    .count-box{
       
        width: 55px;
        height: 37px;
        border-radius: 5px;
      
    }
    .text-heading-small{
        margin: 0;
        font-size: 13px;
    }
    .count-text{
       
        font-size: 16px;
        font-weight: 600;
    }
    
    .status-button{
        width: 60px;
        height: 35px;
      
        font-size: 13px;
       
    }
    
}



@media only screen and (max-width:380px){
    .linear-card{
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr 1fr 60px;
        height: 55px;
    }
    .upper-view-box{
        width: 90%;
    }
    .view-button{
        background-color:#7236A0;
        height:25px ;
        width:50px;
        font-size: 10px;
    }
    .nameShadeblock{
        background-color: #E6F2FD;
        width: 98%;
        height: 23px;
    }
    .title-box{
        width: 94%;
        height: 23px;
       
    
    }
    .below-text{
        font-size: 5px;
        /* font-weight: 500; */
    }
    
    .labelText{
        font-size: 6px;
    }

/* uppre count and status button */
    .count-box{
       
        width: 42px;
        height: 30px;
        border-radius: 5px;
      
    }
    .text-heading-small{
        margin: 0;
        font-size: 9px;
    }
    .count-text{
       
        font-size: 13px;
    }
    
    .status-button{
        width: 50px;
        height: 30px;
      
        font-size: 10px;
       
    }
}
