
.file-download-link{
    margin: 0;
    color: rgb(10, 10, 238);
    font-size: 13px;
    cursor: pointer;
}

.file-download-link:hover{
  color: rgb(52, 52, 143);
  text-decoration: underline;
  opacity: 0.8;
}
.file-download-link:active{
    font-size: 11px;
}