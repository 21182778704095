.signup-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    width: 90%;
    padding-top: 2rem;
    padding-bottom: 1rem;
}
.signup-box h1{
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    align-self: flex-start;
}
.signup-box label{
    font-size: small;
}
.signup-box span{
    font-weight: bold;
    cursor: pointer;
}
.signup-box span:active , .signup-box span:hover{
    opacity:0.8;
    text-decoration: underline;
}
.inputfiled-container{
    width: 100%;
}
.login-txt{
    font-size: small;
    color: rgb(41, 41, 111);
}

.signup-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}
.form-error-txt{
    margin: 0;
    font-size: xx-small;
    color:red;
    margin-top: 0.1rem;
}