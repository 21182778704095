
.signup-form-Outercontainer{
    display: flex;
    justify-content: center;
    /* background-color: aliceblue; */
    width: 100%;
   height: 100vh;
   align-items: center;
}

.signup-form-inner-container{
    background-color: white;
    display: flex;
    width: 60%;
    height: auto;
    justify-content: center;
    align-items: center;

}
.sign-in-text{
    color: blue;
    cursor: pointer;
}
.sign-in-text:hover{
    opacity: 0.7;
}
.sign-in-text:active{
    opacity: 0.3;
}
@media only screen and (max-width:500px){
    .signup-form-inner-container{
        width: 100%;
    }
    .signup-form-Outercontainer{
        height: auto;
    }
  
}