/* Modal Overlay (Backdrop) */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Box */
  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Modal Title */
  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 16px;
  }
  
  .form-group label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 4px;
  }
  
  /* Input and Textarea Fields */
  .input-field,
  .textarea-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .textarea-field {
    height: 80px;
    resize: vertical;
  }
  
  /* Buttons */
  .modal-actions {
    display: flex;
    gap: 8px;
  }
  
  .btn-primary {
    background: #141415;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background: #111112;
  }
  
  .btn-outline {
    background: transparent;
    border: 1px solid #e2e8f0;
    color: #4a5568;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .btn-outline:hover {
    background: #f1f5f9;
  }
  
  .projct-error-txt{
    margin: 0px;
    margin-top: 0.2rem;
    color:red;
    font-size: xx-small;
  }
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  