@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.card-main-container{
    /* width: 200px; */
    max-height: 230px;
    display:flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}
.card-content{
    margin: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.view-text-icon-div{
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.card-form-view-typography{
font-weight: 300;
font-size: 1rem ;
color: blue;
cursor: pointer;
font-weight: 400;
margin-left: 40%;
margin-top: 0;
margin-bottom: 0;

}

.card-form-view-typography:hover{
    text-decoration: underline;
    transform: scale(1.1);
}

.card-form-title{
    display: inline-block;
    margin: 0 0.5rem;
    font-weight:500;
    font-size:0.8rem;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-variation-settings:
    "wdth" 100;
}

