
.super-admin-view-reg-container{
   display: flex;
   flex-direction: column;
   /* justify-content: center; */
   align-items: center;
   padding-top: 20px;

}

.super-admin-formdata{
    width: 80%;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-direction: row;
    gap: 3px;
    box-shadow: 10px 0px 10px rgb(0,0,0,0.2);
}