@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.login-outer-container{
    display: flex;
    background-color:rgb(242, 242, 242);
    flex-direction: column;
    justify-content: center;
    height:100dvh;  
    width:100%;
    align-items: center;
}

.login-inner-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 60%;
    height: auto;
    min-height: 400px;
    background-color: #FFFF;
    border-radius: 10px;
    box-shadow: 10px 0px 10px rgb(0,0,0,0.2);
}

.login-box-left{
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;
}


.login-box-right{
   justify-content: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   align-items: center;
    background-image: linear-gradient(#9181F4, #5038ED);
}
.login-box-right p{
    color: #FFFF;
}
.title1{
    margin: 0;
    margin-top:10px;
    font-size: medium;
    font-weight: 500;
}
.title2{
    margin-top:5px;
    font-size: smaller;
    font-weight: 300;
}
.img-container{
    border: #c4c1c1 1px solid;
    border-radius: 10px;
    padding: 1rem;

    background-color:#9181e8;
}
.img-container img{
   width: 150px;
   height: auto;
}


@media only screen and (max-width:900px){
    .login-inner-box{
        width: 90%;
    }
}

@media only screen and (max-width:550px){
   
    .login-inner-box{
     width: 90%;
     grid-template-columns: 1fr;
     
    }
    .login-box-right{
       display: none;
    }
    
}