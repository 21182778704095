/* CSS */
.button-18 {
    align-items: center;
    background-color: #6C6EEF;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size:medium;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
  }
  .button-18:hover,
  .button-18:focus { 
    background-color: #4143bf;
    color: #ffffff;
  }
  
  .button-18:active {
    background: #4143bf;
    color: rgb(255, 255, 255, .7);
  }
  
  .button-18:disabled { 
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }
  
  /* button19 - used in signup */
.button-19 {
    align-items: center;
    background-color: #00A4EB;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 500;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 50px;
    min-width: 180px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
  }
  
  .button-19:hover,
  .button-19:focus { 
    background-color: #258CB8;
    color: #ffffff;
  }
  
  .button-19:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
  }
  
  .button-19:disabled { 
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }