
.appbar-container{
    height: 100px;
    width: 100%;
    background-color: #575657;
    display: grid;
    grid-template-columns: 300px 1fr 250px;
}

.icon-block{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-img{
    width: 200px;
    cursor:pointer;
}
/* .icon-img:active{
    width: 199px;
} */

.midle-block{
    /* background-color: cadetblue; */
}
.end-block{
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-circle{
    height: 60px;
    width: 60px;
    border-radius: 40px;
    border-width: 1px;
    border-color:white;
    display: flow-root;
    background-color: green; 
}

.profilre-pic{
    height:100%;
    width: 100%;
    border-radius: 50%;
}

.name-text{
    margin: 0;
    color: white;
    font-size: 12px; 
    font-weight: 600;   
}

@media only screen and (max-width:800px){
    /* .appbar-container{
       
        grid-template-columns: 200px 1fr 0px;
    } */
    
    .icon-img{
        width: 150px;
    }
}

@media only screen and (max-width:500px){
    .appbar-container{
       height: 70px;
        grid-template-columns: 150px 1fr 100px;
    }
    
    .icon-img{
        width: 100px;
    }
    .profile-circle{
        height: 40px;
        width: 40px;
        border-radius: 40px;
       
    }
    
    .name-text{
      margin-top: 6px;
      margin-right: 4px;
        color: white;
        font-size: 5px; 
        font-weight: 600;   
    }
}