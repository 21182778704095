@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');


/* .data-empty-container{

} */

.inner-container{
display: flex;
justify-content: center;
margin-top: 200px;
align-items: center;
}

.text-message{
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    white-space:nowrap;
}

@media only screen and (max-width:800px){
    .text-message{
        font-size: 33px;
    }
}
@media only screen and (max-width:500px){
    .text-message{
        font-size: 23px;
    }
}
@media only screen and (max-width:350px){
    .text-message{
        font-size: 17px;
    }
}