@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.form-profile-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.fomrm-header-container{
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 0px 0px;
    background-color:'';
    border-bottom: 1px solid #ccc;
}
.form-img-container{
    overflow: hidden;
   width: 40px;
   height:40px ;
   border-radius: 100%;
   cursor: pointer;
}
.form-img-style{
    height: 100%;
    width:40px;
}
.form-profile-name{
    font-family: "Open Sans", sans-serif;
    font-size: small;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
}

/* ---------- */
.dropdown-menu {
    position: absolute;
    right: 5px;
    top: 60px;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 150px;
    padding: 8px 0;
    z-index: 100;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    gap: 3px;
    transition: background 0.3s ease;
  }
  
  .dropdown-item:hover {
    background: #f1f1f1;
  }
  
  .dropdown-icon {
    margin-right: 10px;
  }
/* logo  */
.form-logo-container{
  padding: 0.3rem 0.5rem;
  cursor: pointer;
}
  .form-logo-style{
    width: 50px;
    height: auto;
  }

  /* logo title -logo wrap */
  .form-log-text-wraper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-logo-text{
    font-family: "Roboto Mono", monospace;
    font-size: large;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  