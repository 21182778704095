
.text-padding-dialog{
    margin-bottom:  20px !important;
    margin-top: 10px !important;
    background-color: rgb(238, 238, 238) !important;
}

.list-container{
    display: flex;
    flex-direction: row;

    /* padding-left: 10px; */
}

#remove-icon-list {
    list-style-type: none; /* Remove default list styling */
  }
  
  #remove-icon-list > #list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px; /* Add margin between items */
  }

  .span-color{
    color: red;
  }