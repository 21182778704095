@import url('https://fonts.googleapis.com/css2?family=Junge&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.participants-Registration-container {
  /* background-color: rgb(157, 186, 240); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
   /* Adjust as needed */
  }
  .titleImageContainer{
    display: flex;
    /* background-color: rgb(236, 151, 40); */
    width: 100%;
    justify-content: center;
  }

  .titleImage{
    width: 840px;
    /* height: 200px; */
  }
  .event-parti-title-container {
    margin-top: 20px; /* Adjust as needed */
    margin-bottom: 20px;
  }
  
  .event-partic-form-container {
    /* background-color: aquamarine; */
    /* background-color: rgb(193, 212, 246); */
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  
  .event-partic-textbox-container {
    /* background-color: rgb(88, 124, 124); */
    display: flex;
    width:20cm;
    flex-direction: column;
    align-items: flex-start; 
    margin-bottom: 15px;
  }

  .generated-form-title{
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 25px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    /* font-size:30px; */
    text-align:center; 
    line-height:1.5em; 
    /* padding-bottom:45px; */
    /* font-family:"Playfair Display", serif;  */
    font-family: "Nunito Sans", sans-serif;
    text-transform:uppercase;
  }

  
  .label {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .event-textfield-style {
    width: 100% /* Adjust as needed */
  }
  
  .event-partic-button-view{
    margin-bottom: 20px;
  
  
  }
  
  .event-partici-button{
    background-color: rgb(84, 84, 114) !important;
  }

  

  @media only screen and (max-width:800px){
    .event-partic-textbox-container {
      width: 12cm;
    }
    .titleImage{
      width: 100%;
     
    }
    /* .event-textfield-style {
      width: 400px;
    } */
  }

  @media only screen and (max-width:500px){
  
    .event-partic-textbox-container {
      width: 8cm;
    }
    .event-textfield-style {
      width: 100%;
      font-size: 12px;
    }
    .generated-form-title{
      font-size: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .label{
      font-size: 14px;
    }

  }


  /* text widget-(label) style */

  .text-label-h3{
    
    padding: 0;
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
    color: #000000;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    flex-wrap: wrap; 
    overflow: hidden; /* Hides any overflow text */
    text-overflow: ellipsis;

  }
