
.su-login-container{
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 110vh;
    
}

.su-login-box{
    background-color: aquamarine;
    height: 300px;
    width: 400px;

}