/* outer div */
.settings-outer-container{
    background-color:white;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 20px;
}

/* box */
.settings-inner-container{
width: 60%;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
padding-top: 20px;
flex-direction: column;
align-items: center;


}
.form-box-settings{
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: row;
    border: 1px solid #edebeb;
    padding: 0px 0.4rem;
    box-shadow: 1px 2px 3px rgba(133, 131, 131, 0.2);     
    padding-left: 30px;         
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 0.5rem;
}
.url-div{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    
    
}
.copy-button{
font-size:0.8rem;
font-family: "Open Sans", sans-serif;
white-space: nowrap;
cursor: pointer;
border-width: 1;
margin-right: 3px;
padding: 0.3rem 0.4rem;
background-color: rgb(12, 12, 12);
border-radius: 8px;
border-style: solid;
border-color: rgb(23, 22, 22);
color: white;
}

.copy-button:hover, .copy-button:active{
    transform: scale(1.1);    
}


.url-text-style{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size:0.7rem;
    font-style :italic;
    font-weight :400;
    cursor: pointer;
    color: blue;
   margin-top: 2rem;
}

.url-text-style:hover{
 opacity: 0.8;
 text-decoration: underline;
}

.deleteIcon{
 
}
.deleteButton{
    display: flex;
    width:40px;
    height:40px;
    justify-content: center;
    align-items: center;
    border-width: 0;
    border-radius: 20px;
    background-color: rgb(239, 239, 239);
    cursor: pointer;
}
.deleteButton:hover{
    background-color: rgb(228, 228, 228);
}
.deleteButton:active{
    width:35px;
    height:35px;
}

.text-inside-box{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 0.8rem;
    white-space: nowrap;
    position: absolute;
    top:5px;
    left: 10px;
    margin: 0;
   color: rgb(7, 7, 143); 
}
.sub-text-inside-box{
    font-family: "Open Sans", sans-serif;
    font-size: 0.8rem;
    margin-top: 2rem;
    color: #5b5555;
}

/* @media only screen and (max-width:'1456px'){
    .url-text-style{
        font-size: 10px;
    }
} */
@media only screen and (max-width:1200px){
    .box-settings{
        width: 100%;
     }
}
@media only screen and (max-width:800px){
    .box-settings{
 
        width: 100%;
     }
     /* .url-text-style{
        font-size: 15px;
     } */
}

@media only screen and (max-width:600px){
    .settings-outer-container{
        width:90%;
    }
    /* .url-div{
        width: 70%;  
        overflow: hidden;
    } */
    .settings-inner-container{
        width: 90%;
        gap: 1rem;
    }
    .box-settings{
       width: 100%;
       padding-left:4px;

    }
    .url-text-style{
        font-size: 12px;
        /* white-space: wrap; */
        word-wrap: break-word; /* Allows long words to break and wrap onto the next line */
        overflow-wrap: break-word; /* Alternate property for compatibility */
        word-break: break-all
     }
     .copy-button{
        font-size: 11px;
        width:65px ;
        height: 35px;
    }
   
}

@media only screen and (max-width:386px){
    .box-settings{
        width: 97%;
        display: flex;
        flex-direction: row;

    }
    .copy-button{
        font-size: 11px;
        width:65px ;
        height: 35px;
    }

    .text-inside-box{
        font-size: 18px;
     }
    
    
}