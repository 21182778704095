@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.home-outer-container{
    /* background-color:  aliceblue; */
    /* min-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 60px;
    margin-top: 20px;
}

.form-home-title-container{
  display: flex;
  justify-content: center;

  position: relative;
  width:100%;
       
}
.form-home-button{
    position: absolute;
    right: 0.4rem;
    top: 0;
    background-color: rgb(46, 44, 44);
    color: white;
    border-radius: 0.2rem;
    padding: 0.3rem 0.5rem;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
    cursor: pointer;
}
.form-home-button:hover , .form-home-button:active{
    transform: scale(1.1);
    right:0.5rem;
    border-radius: 0.3rem;

}

.home-title{
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 1.5rem;
}

.home-inner-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    width:70%;
    background-color: #fff;
    border: 1px solid #121111;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
    padding: 20px;
    
}
/* style={{paddingLeft:'26px',paddingRight:'26px'}} */
.home-card-container{
    width: 100%;
    padding-left: 26px;
    padding-right: 26px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
}

.dynamic-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 26px; /* Adjust the gap between cards as needed */
  }

  @media only screen and (max-width:1200px){
    .home-inner-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width:900px){
    .home-inner-container{
        grid-template-columns: 1fr 1fr 1fr ;
    }
  }

  @media only screen and (max-width:650px){
    .home-inner-container{
        grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width:500px){
    .home-title{
      font-size: 15px;
    }
    .home-inner-container{
      width:60%;
      grid-template-columns: 1fr;
    }
  }