
.table-delete-button,
 .table-view-button{
        height:35px ;
        width:80px;
        font-size: 13px;
        font-weight: 700;
        border-radius: 20px;
        cursor: pointer;
        transition: opacity 0.15s;
        font-family: "Montserrat", sans-serif;
        border-width: 2px;
        border-style: solid;
      font-optical-sizing: auto;
      font-weight: 500;  
}
.table-view-button{
  background-color:#444444;
  color: rgb(255, 255, 255);
  border-color: #474747;
}
.table-view-button:active{
    opacity: 0.8;
}

.table-delete-button{
  background-color: rgb(205, 205, 205);
  border-color: rgb(238, 238, 238);
  color: rgb(158, 153, 153);

}
.head-cell-text{

  font-weight: bold !important;
}