.outer-container{
    /* height: 100vh; */
    /* display: flex; */
    /* background-color: rgb(217, 160, 160); */
    justify-content: center;
    /* align-items: center; */

}

.inner-container{
    /* margin-bottom: 20px; */
    background-color: rgb(255, 255, 255);
    

}

.sub-close-title{
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 75px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    /* font-size:30px; */
    text-align:center; 
    line-height:1.5em; 
    padding-bottom:45px;
    font-family:"Playfair Display", serif; 
    /* text-transform:uppercase; */
}

.title-close{
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 65px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    /* font-size:30px; */
    text-align:center; 
    line-height:1.5em; 
    padding-bottom:45px;
    font-family:"Playfair Display", serif; 

}

@media only screen and (max-width:1000px){
    .sub-close-title{
        font-size: 55px;
    }
}

@media only screen and (max-width:500px){
    .sub-close-title{
        font-size: 35px;
    }
}