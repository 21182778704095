.textfield{
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
    margin: 0;
}

.textfield:focus{
    outline: none;
    border-color: #0077CC;
    box-shadow: 0 0 0 2px rgba(0, 119, 204, 0.2);
}