.card-custom-main-container{
    /* width: 200px; */
    /* height: 230px; */
    cursor: pointer;
    display:flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    /* padding-top: 20px; */
}

.card-custom-main-container:hover{
    /* width: 180px;
    height: 210px; */
    opacity: 0.8;
}