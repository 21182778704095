
.super-admin-viewlist{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.export-button-view{
    /* background-color: aqua; */
    width: 90%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.export-button{
background-color:#b4b3b3;
width: 80px;
height: 30px;
cursor: pointer;
}

.super-table-view-container{
    width: 90%;
}

