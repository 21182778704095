.project-container{
    display: flex;
    flex-direction: column;
    padding: 1% 5%;
}
.project-header-list{
    display: flex;
    width: 100%;
    justify-content: end;
}
.project-button{
    padding: 0.5rem 1rem ;
    background-color: rgb(49, 49, 49);
    color: white;
    font-size: small;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    align-self: flex-end;
    cursor: pointer;
}
.project-button:hover , .project-button:active{
    transform: scale(1.1);
}
.project-list-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
}

@media only screen and (max-width: 1200px) {
    .project-list-grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 900px) {
    .project-list-grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .project-list-grid{
        grid-template-columns: 1fr;
    }
}