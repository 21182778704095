.login-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    padding-bottom:2rem;
    padding-top: 2rem;
    width: 80%;
}
.login-container h1{
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
   align-self: flex-start;
}
.login-otp{
    margin: 0;
    color: #7b7a7a;
    font-size: medium;
    font-weight: 400;
    text-decoration: underline; 
    cursor: pointer;  
}
.login-otp:hover,.login-otp:active{
    text-decoration: none;
    color: #a7a6a6;
}
.login-label{
   font-size: medium;
}
.login-input-container{
    display:flex;
    flex-direction: column;
    width: 100%;
   gap:0.5rem;
}
.google-icon{
    cursor: pointer;
    height: 25px;
    width: 25px;
}
.line-border{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap: 0.5rem;
}
.line{
    height: 1px;
    width: 100%;
    background-color: #ccc;
}
.line-txt{
    color: #929292;
    font-size: medium;
}
.signup-txt{
    font-size: small;
    color: rgb(41, 41, 111);
}
.login-container span{
    font-weight: bold;
    cursor: pointer;
}
.login-container span:active ,.login-container span:hover{
    opacity:0.8;
    text-decoration: underline;
}

/* 8888888 */
.forgot-password{
    margin: 0;
    cursor: pointer;
    color: blue;
    font-size: small;
    align-self: flex-end;
}

.forgot-password:hover{
    text-decoration: underline;
    opacity: 0.7;
}
.forgot-password:active{
    opacity: 0.3;
}